export const Path = {
  Login: '/',
  Dashboard: '/dashboard',
  LandingPage: '/landing',
  AddLanding: '/add_landing',
  EditLanding: '/edit_landing',
  JobPostPage: '/job_post',
  HotCompanyPage: '/hot_company',
  CompanyVerificationPage: '/company_verification',
  Transaction: '/transaction',
  TransactionTradesys: '/transaction_tradesys',
  TransactionInfo: '/transaction/:id',
  Import: '/import',
  Product: '/product',
  ProductInfo: '/product/:code',
  ProductSerial: '/product-serial',
  Payment: '/payment',
  Customer: '/customer',
  CustomerInfo: '/customer/:id',
  Contract: '/contract',
  ContractInfo: '/contract/:contractNumber',
  StatementChecker: '/statement',
  NewCustomer: '/new_customer',
  Notification: '/notification',
  TrackPayment: '/track',
  TrackPaymentInfo: '/track/:id',
  ThaiQRLog: '/thai-qr',
  ManageUser: '/manage-user',
  CreateAccount: '/create-account',
  CreateEmail: '/create-email',
  ManageAccount: '/manage-account',
  ManageIcloudAccount: '/manage-icloud-account',
  ManageTelephone: '/manage-telephone-number',
  Refinance: '/refinance',
  MonitorCustomer: '/monitor-customer',
  SchedulerDashboard: '/scheduler-dashboard',
  CreatePick: '/create-pick',
  ProductsPick: '/products-pick',
  PrintPick: '/print-pick',
  ReciveItems: '/recieve-items',
  ReportPick: '/report-pick',
  CheckStock: '/check-stock',
  MonitorStockReport: '/report-monitor-stock',
  AdjustStock: '/adjust-stock',
  AdjustStockReport: '/adjust-stock-report',
  UnlockIcloudAccounting: '/unlock-icloud-accounting',
  UnlockIcloudCRM: '/unlock-icloud-crm',
  TrackingNoInfo: '/terminate-contract',
  AddTrackingNo: '/add_trackingno',
  TrackingTaskInfo: '/field-collection',
  BlacklistCustomer: '/blacklist-customer',
  DashboardProducts: '/dashboard-products',
  ManagePackage: '/manage-package',
  CreatePickNonSerial: '/create-pick-non-serial',
  RecieveItemsNonSerial: '/recive-items-non-serial',
  AdjustStockNonSerial: '/adjust-stock-non-serial',
  PickNonSerialReport: '/pick-non-serial-report',
  AdjustStockNonSerialReport: '/adjust-stock-non-serial-report',
  MonitorStockNonserialReport: '/monitor-stock-non-serial-serial',
  FollowTrack: '/follow-track-report',
  FollowTrackDetail: '/follow-track-detail-report',
  DIFItemMaster: '/DIFItemMaster',
  DIFARMaster: '/DIFARMaster',
  DIFAPMaster: '/DIFAPMaster',
  DIFInvoice: '/DIFInvoice',
  DIFInvoiceDetail: '/DIFInvoiceDetail',
  DIFSerialTrans: '/DIFSerialTrans',
  DIFReceipt: '/DIFReceipt',
  DIFReceiptDetail: '/DIFReceiptDetail',
  DIFRecMoney: '/DIFRecMoney',
  DIFGPInvoice: '/DIFGPInvoice',
  DIFGPInvoiceDetail: '/DIFGPInvoiceDetail',
  BuyStockReport: '/report-buy-stock',
  ManageDEPScreen: '/manage-dep',
  StockSerialReport: '/report-stock-serial',
  StockBranchReport: '/report-stock-branch',
  CountStockReport: '/report-count-stock',
  MiradoreConfig: '/miradore-config-profile',
  ReportTrackingFee: '/report-tracking-fee',
  ReportTrackingDaily: '/report-tracking-daily',
  ReportTrackingAgent: '/report-tracking-agent',
  InterestRateRefinanceTableScreen: '/interest-rate-refinance-table',
  ReportDownPayment: '/report-down-payment',
  ReportDownPaymentDaily: '/report-down-payment-daily',
  StatementDownPayment: '/statement-down-payment',
  FinanceDownPayment: '/finance-down-payment',
  IcloudTrackingStatus: '/icloud-tracking-status',
  IcloudTrackingStatusLog: '/icloud-tracking-status-log',
  ApproveSecondHand: '/approve-second-hand',
  BuySecondHandReportScreen: '/buy-second-hand-report',
  AddNewRecordSecondHand: '/add-new-record-second-hand',
  EditRecordSecondHand: '/edit-record-second-hand',
  ManageSpecialProductScreen: '/manage-special-product',
  EditSecondHandPriceScreen: '/edit-second-hand-price',
  MovementReportScreen: '/movement-report-stock',
  TrackingNoReport: '/tracking-no-report',
  AddAcknowledgementTrackingNo:'/add_AcknowledgementReceipt_tracking_no',
  TerminatedContractReport:'/terminated-contract-report',
  MistakeTerminateContract:'/mistake-terminate-contract',
  CheckLocationHistory:'/check-location-history',
  ReserveProductStockReportScreen: '/reserve-product-stock-report',
  DeviceReturn:'/device-return',
  TrackingTaskManage:'/tracking-task-manage',
  SignRecordSecondHand: '/sign-second-hand',
  FGFApproveCommission: '/friend-get-friend-approve-commission',

  AphBuySecondHandReportScreen: '/aph-buy-second-hand-report',
  AphAddNewRecordSecondHand: '/aph-add-new-record-second-hand',
  AphEditRecordSecondHand: '/aph-edit-record-second-hand',
  AphSignRecordSecondHand: '/aph-sign-second-hand',
  AphApproveSecondHandScreen: '/aph-approve-second-hand',
  AphSignRecordSecondHandByCustomer: '/aph-sign-second-hand-by-customer/:encryptData',
};

export const PageName = [
  {
    path: '/',
    name: 'login',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
  },
  {
    path: '/transaction',
    name: 'Transation',
  },
  {
    path: '/transaction_tradesys',
    name: 'Transation Update',
  },
  {
    path: '/payment',
    name: 'Receipt',
  },
  {
    path: '/thai-qr',
    name: 'Thai Qr',
  },
  {
    path: '/customer',
    name: 'Customer',
  },
  {
    path: '/new_customer',
    name: 'New Customer',
  },
  {
    path: '/notification',
    name: 'Notification',
  },
  {
    path: '/track',
    name: 'เร่งรัด/ตามหนี้',
  },
  {
    path: '/contract',
    name: 'Contract',
  },
  {
    path: '/product',
    name: 'Product',
  },
  {
    path: '/product-serial',
    name: 'Product Serial',
  },
  {
    path: '/import',
    name: 'Import',
  },
  {
    path: '/statement',
    name: 'Statement Tool',
  },
  {
    path: '/manage-user',
    name: 'Manage User',
  },
  {
    path: '/create-account',
    name: 'Create Email and iCloud',
  },
  {
    path: '/create-email',
    name: 'Create Email',
  },
  {
    path: '/dashboard-account',
    name: 'Dashboard Account',
  },
  {
    path: '/dashboard-icloud',
    name: 'Dashboard Icloud',
  },
  {
    path: '/refinance',
    name: 'Refinance',
  },
  {
    path: '/monitor-customer',
    name: 'พิจารณาลูกค้า',
  },
  {
    path: '/scheduler-dashboard',
    name: 'ปฎิทินนัดทำสัญญา',
  },
  {
    path: '/unlock-icloud-accounting',
    name: 'ปลดล็อก MDM และ Approve รหัส iCloud',
  },
  {
    path: 'unlock-icloud-crm',
    name: 'ปลดล็อกรหัส iCloud ฝ่าย CRM',
  },
  {
    path: '/products-pick',
    name: 'สร้างใบจัดสินค้า',
  },
  {
    path: '/recieve-items',
    name: 'รับสินค้า',
  },
  {
    path: '/report-pick',
    name: 'รายงานจัดสินค้า',
  },
  {
    path: '/report-monitor-stock',
    name: 'รายงานติดตามสต็อก',
  },
  {
    path: '/adjust-stock',
    name: 'ปรับปรุงสต็อก',
  },
  {
    path: '/adjust-stock2',
    name: 'ปรับปรุงสต็อกทดสอบ',
  },
  {
    path: '/adjust-stock-report',
    name: 'รายงานปรับปรุงสต็อก',
  },
  {
    path: '/blacklist-customer',
    name: 'blacklist ลูกค้า',
  },
  {
    path: '/terminate-contract',
    name: 'Terminate Contract',
  },
  {
    path: '/field-collection',
    name: 'Field Collection',
  },
  {
    path: '/dashboard-products',
    name: 'จัดการสินค้า non serial',
  },
  {
    path: '/create-pick-non-serial',
    name: 'สร้างใบจัดสินค้า Non Serial',
  },
  {
    path: '/recive-items-non-serial',
    name: 'รับสินค้า',
  },
  {
    path: '/adjust-stock-non-serial',
    name: 'ปรับปรุงสินค้า Non Serial',
  },
  {
    path: '/pick-non-serial-report',
    name: 'รายงานการจัดสินค้า',
  },
  {
    path: '/adjust-stock-non-serial-report',
    name: 'รายงานปรับปรุงสต็อก',
  },
  {
    path: '/monitor-stock-non-serial-serial',
    name: 'รายงานติดตามสต็อกสินค้า',
  },
  {
    path: '/DIFARMaster',
    name: 'Master ลูกหนี้',
  },
  {
    path: '/DIFAPMaster',
    name: 'Master เจ้าหนี้',
  },
  {
    path: '/DIFItemMaster',
    name: 'Master ร้านค้า',
  },
  {
    path: '/DIFInvoice',
    name: 'รายการบิลขาย',
  },
  {
    path: '/DIFInvoiceDetail',
    name: 'รายการขายสินค้า',
  },
  {
    path: '/DIFSerialTrans',
    name: 'รายการ Serial ที่ทำรายการรายวัน',
  },
  {
    path: '/DIFReceipt',
    name: 'รายการบิลรับชำระหนี้',
  },
  {
    path: '/DIFReceiptDetail',
    name: 'รายการรับชำระเงิน',
  },
  {
    path: '/DIFRecMoney',
    name: 'ประเภทการรับเงิน',
  },
  {
    path: '/DIFGPInvoice',
    name: 'รายการบิลซื้อ',
  },
  {
    path: '/DIFGPInvoiceDetail',
    name: 'รายการซื้อสินค้า',
  },
  {
    path: '/follow-track-report',
    name: 'บันทึกการติดตามรายคน'
  },
  {
    path: '/follow-track-detail-report',
    name: 'รายละเอียดบันทึกการติดตามรายคน'
  },
  {
    path: "/report-buy-stock",
    name: "รายงานใบสั่งซื้อ",
  },
  {
    path: "/manage-dep",
    name: "จัดการ DEP",
  },
  {
    path: "/report-stock-serial",
    name: "รายงานสต็อกแยกตาม Serial",
  },
  {
    path: "/report-stock-branch",
    name: "รายงานสต็อกแยกตามสาขา",
  },
  {
    path: "/report-count-stock",
    name: "รายงานนับสต็อก",
  },
  {
    path: '/miradore-config-profile',
    name: 'Miradore Config'
  },
  {
    path: '/report-tracking-fee',
    name: 'รายงานค่าติดตามภาคสนามรายเดือน'
  },
  {
    path: '/report-tracking-daily',
    name: 'รายงานประวัติการติดตามภาคสนามรายวัน'
  },
  {
    path: '/report-tracking-agent',
    name: 'รายงานประวัติการติดตามภาคสนามรายบุคคล'
  },
  {
    path: '/interest-rate-refinance-table',
    name: 'ตารางราคา และอัตราดอกเบี้ย'
  },
  {
    path: '/report-down-payment',
    name: 'รายงานยอดออมดาวน์'
  },
  {
    path: '/report-down-payment-daily',
    name: 'รายงานยอดออมดาวน์รายวัน'
  },
  {
    path: '/statement-down-payment',
    name: 'รายงานออมดาวน์'
  },
  {
    path: '/finance-down-payment',
    name: 'ออมดาวน์'
  },
  {
    path: '/icloud-tracking-status',
    name: 'สถานะการติดตาม icloud'
  },
  {
    path: '/icloud-tracking-status-log',
    name: 'Log สถานะการติดตาม icloud'
  },
  {
    path: '/approve-second-hand',
    name: 'อนุมัติจ่ายเงินรับซื้อสินค้ามือสอง'
  },
  {
    path: '/buy-second-hand-report',
    name: 'รายงานรับซื้อสินค้ามือสอง'
  },
  {
    path: '/add-new-record-second-hand',
    name: 'เพิ่มรายการรับซื้อสินค้ามือสอง'
  },
  {
    path: '/edit-record-second-hand',
    name: 'แก้ไขรายการรับซื้อสินค้ามือสอง'
  },
  {
    path: '/manage-special-product',
    name: 'จัดการสินค้าพิเศษ'
  },
  {
    path: '/edit-second-hand-price',
    name: 'แก้ไขราคาสินค้ารับซื้อมือสอง'
  },
  {
    path: '/movement-report-stock',
    name: 'รายงาน Movement'
  },
  {
    path: '/reserve-product-stock-report',
    name: 'รายงานการจองสินค้าในระบบ',
  },
  {
    path: '/sign-second-hand',
    name: 'เซ็นสัญญารายการรับซื้อสินค้ามือสอง'
  },
  {
    path: '/aph-buy-second-hand-report',
    name: 'รายงานรับซื้อสินค้ามือสอง'
  },
  {
    path: '/aph-add-new-record-second-hand',
    name: 'เพิ่มรายการรับซื้อสินค้ามือสอง'
  },
  {
    path: '/aph-edit-record-second-hand',
    name: 'แก้ไขรายการรับซื้อสินค้ามือสอง'
  },
  {
    path: '/aph-sign-second-hand',
    name: 'เซ็นสัญญารายการรับซื้อสินค้ามือสอง'
  },
  {
    path: '/aph-approve-second-hand',
    name: 'อนุมัติจ่ายเงินรับซื้อสินค้ามือสอง'
  },
  {
    path: '/aph-sign-second-hand-by-customer',
    name: 'เซ็นสัญญารายการรับซื้อสินค้ามือสอง สำหรับลูกค้า'
  },
];
