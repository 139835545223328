import moment from 'moment';

export const ManageAccountDashboardSearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
      rules: {
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
        maxlength: {
          value: 10,
          message: 'กรุณากรอกไม่เกิน 10 ตัวอักษร',
        },
      },
    },
    {
      key: '2',
      span: 6,
      name: 'mail',
      label: 'อีเมล',
      placeholder: 'กรุณากรอกอีเมล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'status',
      label: 'สถานะ icloud',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสถานะ',
      allowClear: true,
      options: [
        {value: 'WAIT_REGISTER', label: 'รอสมัครไอคลาวน์'},
        {value: 'ACTIVE', label: 'พร้อมใช้งาน'},
        {value: 'INACTIVE', label: 'กำลังใช้งาน'},
        {value: 'WAIT', label: 'รอจัดการ'},
        {value: 'CLOSED', label: 'ปิดใช้งาน'},
      ],
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'icloud_status',
      label: 'สถานะติดตาม icloud',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสถานะ',
      allowClear: true,
      options: option.icloudStatusOption,
      value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'createUser',
      label: 'ผู้สมัคร icloud',
      placeholder: 'กรุณากรอกชื่อผู้สมัคร icloud',
      type: 'INPUT',
      value: '',
    },
    {
      key: '56',
      span: 6,
      name: 'startDate',
      label: 'วันที่สมัคร',
      placeholder: 'เลือกวันที่สมัคร',
      type: 'DATE_PICKER',
    },
    {
      key: '7',
      span: 6,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'ถึงวันที่',
      type: 'DATE_PICKER',
    },
  ];
};

export const ManageIcloudAccountDashboardSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
      rules: {
        maxLength: {
          value: 10,
          message: 10,
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '2',
      span: 6,
      name: 'mail',
      label: 'อีเมล',
      placeholder: 'กรุณากรอกอีเมล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'status',
      label: 'สถานะ',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสถานะ',
      allowClear: true,
      options: [
        {value: 'ACTIVE', label: 'กำลังใช้งาน'},
        {value: 'WAIT_ADMIN_CONFIRM', label: 'รอเจ้าหน้าที่ตรวจสอบ'},
        {value: 'WAIT_FINANCE_APPROVE', label: 'รอการเงินอนุมัติ'},
        {value: 'WAIT_SIGNATURE', label: 'รอเซ็นสัญญา'},
      ],
      value: '',
    },
  ];
};

export const ManageTelephoneSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
      rules: {
        maxLength: {
          value: 10,
          message: 10,
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
  ];
};

export const RefinanceSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'contractNo',
      label: 'เลขที่สัญญา',
      placeholder: 'กรอกเลขที่สัญญา',
      type: 'INPUT',
      value: '',
    },
  ];
};
export const TrackingNoDashboardSearchFrom = ({option}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'contractNO',
      label: 'เลขที่สัญญา',
      placeholder: 'กรอกเลขที่สัญญา',
      type: 'INPUT',
      // options: options(data, 'contractNO'),
      // options: option.contractNoOptions,
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อผู้ทำสัญญา',
      placeholder: 'กรอกชื่อ-นามสกุล',
      type: 'INPUT',
      // options: options(data, 'name'),
      // options: option.customerNameOptions,
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'contractStatus',
      label: 'สถานะสัญญา',
      placeholder: 'เลือก',
      type: 'SELECT',
      allowClear: true,
      options: option.contractOptions,
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'status',
      label: 'สถานะการจัดส่ง',
      placeholder: 'เลือก',
      type: 'SELECT',
      allowClear: true,
      options: option.sendingOptions,
      value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'trackingTaskStatus',
      label: 'ภาคสนาม',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option.trackingOptions,
      value: '',
    },
    {
      key: '6',
      span: 6,
      name: 'date',
      label: 'ช่วงวันที่จัดส่ง',
      placeholder: ['วันที่เริ่มต้น', 'วันที่สิ้นสุด'],
      type: 'RANGE_DATE_PICKER',
      value: [],
    },
  ];
};

export const TrackingTaskDashboardSearchFrom = ({data, option}) => {
  const options = (op, title) => {
    const list = op.map((e) => e[title]);
    const set = Array.from(new Set(list));
    const selectOptions = set.map((option) => ({
      label: option,
      value: option,
    }));
    return selectOptions;
  };

  return [
    {
      key: '1',
      span: 4,
      name: 'contractNO',
      label: 'เลขที่สัญญา',
      placeholder: 'กรอกเลขที่สัญญา',
      type: 'INPUT',
      // options: option.contractOptions,
      value: '',
    },
    {
      key: '2',
      span: 4,
      name: 'trackers',
      label: 'ชื่อผู้ติดตาม',
      placeholder: 'กรอกชื่อผู้ติดตาม',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 4,
      name: 'name',
      label: 'ชื่อผู้ทำสัญญา',
      placeholder: 'กรอกชื่อผู้ทำสัญญา',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 4,
      name: 'trackingTaskStatus',
      label: 'สถานะการติดตาม',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option.trackingOptions,
      value: '',
    },
    {
      key: '5',
      span: 4,
      name: 'type',
      label: 'ประเภทหนังสือยกเลิกสัญญา',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option.terminateOption,
      value: '',
    },
    {
      key: '6',
      span: 5,
      name: 'date',
      label: 'ช่วงวันที่รับคำสั่ง',
      placeholder: ['วันที่เริ่มต้น', 'วันที่สิ้นสุด'],
      type: 'RANGE_DATE_PICKER',
      value: [],
    },
  ];
};

export const PickNoSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
      placeholder: 'กรอกเลขเอกสาร',
      type: 'INPUT',
      autoFocus: true,
    },
  ];
};

export const PickNoRecieveSearchForm = ({data, fn}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
      placeholder: 'กรอกเลขเอกสาร',
      type: 'INPUT',
      autoFocus: true,
      defaultValue: data.docNo,
      // onChangeCustom: fn.onChange,
    },
  ];
};

export const ReportMonitorStockSearchForm = ({option, data, fn}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'เลือกสาขา',
      type: 'SELECT',
      options: option.optionBranch,
      defaultValue: data.branchDefault,
      allowClear: true,
    },
    {
      key: '2',
      span: 6,
      name: 'product',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
      // options: option.optionProductName,
      allowClear: true,
      value: '',
    },
    {
      key: '3',
      span: 5,
      name: 'type',
      label: 'ประเภทสินค้า',
      placeholder: 'เลือกประเภทสินค้า',
      type: 'SELECT',
      options: option.optionItemType,
      onChangeCustom: (e) => fn.setField(e, 'type'),
      allowClear: true,
      value: data.typeDefault,
    },
    {
      key: '4',
      span: 4,
      name: 'serial_status',
      label: 'Serial Status',
      placeholder: 'เลือก Serial Status',
      type: 'SELECT',
      options: option.optionSerialStatus,
      onChangeCustom: (e) => fn.setField(e, 'serial_status'),
      allowClear: true,
      value: data.serialStatusDefault,
    },
  ];
};

export const ReportMonitorStockNonSerialSearchForm = ({option, branchDefault}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'เลือกสาขา',
      type: 'SELECT',
      options: option.optionBranch,
      defaultValue: branchDefault,
      allowClear: true,
    },
    {
      key: '2',
      span: 6,
      name: 'product',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
    },
  ];
};

export const UnlockIcloudCRMSearchForm = ({options}) => {
  return [
    {
      key: '2',
      span: 7,
      name: 'user_name',
      label: 'ชื่อ-นามสกุล',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 5,
      name: 'unlock_status',
      label: 'สถานะ',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionsCRM,
      allowClear: true
    },
  ];
};

export const ReportAdjustStockSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'name',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
    },
    {
      key: '2',
      span: 6,
      name: 'serial',
      label: 'ซีเรียล',
      placeholder: 'กรอกซีเรียล',
      type: 'INPUT',
    },
    {
      key: '3',
      span: 6,
      name: 'startDate',
      label: 'วันที่ทำรายการ',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '4',
      span: 6,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    // {
    //   key: '5',
    //   span: 5,
    //   name: 'serial_status',
    //   label: 'Serial Status',
    //   placeholder: 'เลือก Serial Status',
    //   type: 'SELECT',
    //   options: options.optionSerialStatus,
    //   allowClear: true,
    //   // defaultValue: defaultSearch.status,
    //   value: '',
    // },
    {
      key: '5',
      span: 5,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
      placeholder: 'กรอกเลขที่เอกสาร',
      type: 'INPUT',
      options: options.optionSerialStatus,
      allowClear: true,
      // defaultValue: defaultSearch.status,
      value: '',
    },
  ];
};

export const ReportAdjustStockNonSerialSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'name',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
    },
    {
      key: '3',
      span: 6,
      name: 'startDate',
      label: 'วันที่ทำรายการ',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '4',
      span: 6,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
  ];
};

export const BlacklistCustomerSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'id_card',
      label: 'เลขบัตรประชาชน',
      placeholder: 'กรอกเลขบัตรประชาชน',
      type: 'INPUT',
      rules: {
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อ',
      placeholder: 'กรอกชื่อ',
      type: 'INPUT',
    },
    {
      key: '3',
      span: 6,
      name: 'tel',
      label: 'เบอร์',
      placeholder: 'กรอกเบอร์',
      type: 'INPUT',
      rules: {
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
  ];
};
export const ProductsNonStockSearchForm = () => {
  return [
    {
      key: '1',
      span: 12,
      name: 'item_name',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
    },
  ];
};

export const ReportFollowTrackSearchForm = (dataSearch) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'action_by',
      label: 'แอดมินผู้ติดตาม',
      placeholder: 'กรอกแอดมินผู้ติดตาม',
      type: 'INPUT',
    },
    {
      key: '2',
      span: 6,
      name: 'startDate',
      label: 'วันที่',
      placeholder: 'เลือกวันที่',
      defaultValue: (dataSearch.startDate && moment(dataSearch.startDate, 'YYYY-MM-DD')) || '',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 6,
      name: 'endDate',
      label: 'ถึง',
      placeholder: 'เลือกวันที่',
      defaultValue: (dataSearch.endDate && moment(dataSearch.endDate, 'YYYY-MM-DD')) || '',
      type: 'DATE_PICKER',
    },
  ];
};

export const ReportFollowTrackDetailSearchForm = (dataSearch) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'actionBy',
      label: 'แอดมินผู้ติดตาม',
      placeholder: 'กรอกแอดมินผู้ติดตาม',
      type: 'INPUT',
      defaultValue: dataSearch.actionBy !== '' ? dataSearch.actionBy : '',
    },
    {
      key: '2',
      span: 6,
      name: 'startDate',
      label: 'วันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      defaultValue: (dataSearch.startDate && moment(dataSearch.startDate)) || '',
    },
    {
      key: '3',
      span: 6,
      name: 'endDate',
      label: 'ถึง',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      defaultValue: (dataSearch.endDate && moment(dataSearch.endDate)) || '',
    },
    {
      key: '4',
      span: 6,
      name: 'customerName',
      label: 'ชื่อ-นามสกุล (ลูกค้า)',
      placeholder: 'กรอกชื่อ-นามสกุล',
      type: 'INPUT',
    },
  ];
};

export const ReportBuyStockSearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'startDatePurchase',
      label: 'เริ่มวันที่',
      placeholder: 'เลือกวันที่เริ่มสั้่งซื้อ',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 6,
      name: 'endDatePurchase',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 6,
      name: 'docNo',
      label: 'เลขทีใบสั่งซื้อ',
      placeholder: 'กรอกเลขที่ใบสั่งซื้อ',
      type: 'AUTO_COMPLETE',
      options: option.optionDocNo,
      allowClear: true,
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'vendor',
      label: 'Vendor',
      placeholder: 'กรุณาเลือก Vendor',
      type: 'AUTO_COMPLETE',
      options: option.optionVendor,
      allowClear: true,
      value: '',
    },
  ];
};

export const ManageDEPSearchForm = (dataSearch, onClearCustomField, {option}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'docNo',
      label: 'เลขทีใบสั่งซื้อ',
      placeholder: 'กรอกเลขที่ใบสั่งซื้อ',
      type: 'INPUT',
      // type: 'AUTO_COMPLETE',
      // options: option.optionDocNo,
      allowClear: true,
      value: '',
      // value: dataSearch.docNo !== '' ? dataSearch.docNo : null,
      onClear: () => onClearCustomField(),
    },
    {
      key: '2',
      span: 6,
      name: 'appleStatus',
      label: 'Apple Status',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: option.optionAppleStatus,
      allowClear: true,
    },
    {
      key: '3',
      span: 8,
      name: 'productName',
      label: 'ชื่อสินค้า',
      placeholder: 'เลือกชื่อสินค้า',
      type: 'INPUT',
      // options: option.optionProduct,
      allowClear: true,
      value: '',
    },
    {
      key: '4',
      span: 5,
      name: 'type',
      label: 'ประเภทสินค้า',
      placeholder: 'เลือกประเภทสินค้า',
      type: 'SELECT',
      options: option.optionItemType,
      allowClear: true,
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'serialNumber',
      label: 'S/N',
      placeholder: 'กรอกเลข S/N',
      type: 'INPUT',
      // type: 'AUTO_COMPLETE',
      // options: option.optionSerialNumber,
      value: '',
    },
    {
      key: '6',
      span: 5,
      name: 'serialStatus',
      label: 'Serial Status',
      placeholder: 'เลือก Serial Status',
      type: 'SELECT',
      options: [
        {value: '', label: 'ทั้งหมด'},
        {value: 'READY', label: 'พร้อมขาย'},
        {value: 'NOT_READY', label: 'ไม่พร้อมขาย'},
      ],
      allowClear: true,
      value: '',
    },
  ];
};

export const ReportStockSerialSearchForm = ({data, option, fn}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'เลือกสาขา',
      type: 'SELECT',
      options: option.optionBranch,
      // allowClear: true,
      defaultValue: data.branchSearch,
      // value: dataSearch.branch,
      onChangeCustom: (e) => fn.setField(e, 'branch'),
    },
    {
      key: '2',
      span: 6,
      name: 'itemName',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
      // options: option.optionProductName,
      // allowClear: true,
      // value: data.itemNameSearchRef.current,
      // onChangeCustom: (e) => fn.setField(e, 'itemName'),
    },
    {
      key: '3',
      span: 5,
      name: 'type',
      label: 'ประเภทสินค้า',
      placeholder: 'เลือกประเภทสินค้า',
      type: 'SELECT',
      options: option.optionItemType,
      // allowClear: true,
      value: data.itemTypeSearch,
    },
    {
      key: '4',
      span: 6,
      name: 'serialNumber',
      label: 'S/N',
      placeholder: 'กรอกเลข S/N',
      type: 'SELECT',
      options: option.optionSerialNumber,
      allowClear: true,
      value: '',
    },
    {
      key: '5',
      span: 4,
      name: 'appleStatus',
      label: 'Apple Status',
      placeholder: 'เลือก Apple Status',
      type: 'SELECT',
      options: option.optionAppleStatus,
      // allowClear: true,
      value: '',
    },
    {
      key: '6',
      span: 4,
      name: 'itemStatus',
      label: 'สถานะสินค้า',
      placeholder: 'เลือกสถานะสินค้า',
      type: 'SELECT',
      options: option.optionItemStatus,
      // allowClear: true,
      value: '',
    },
    {
      key: '7',
      span: 4,
      name: 'serial_status',
      label: 'Serial Status',
      placeholder: 'เลือกสถานะ​ Serial Status',
      type: 'SELECT',
      options: option.optionSerialStatus,
      // allowClear: true,
      onChangeCustom: (e) => fn.setField(e, 'serial_status'),
      defaultValue: data.serialStatusSearch,
    },
    {
      key: '8',
      span: 10,
      name: 'remark',
      label: 'หมายเหตุ',
      placeholder: 'กรอกหมายเหตุเพื่อค้นหา',
      type: 'INPUT',
      // options: option.optionProductName,
      // allowClear: true,
      // value: data.itemNameSearchRef.current,
      // onChangeCustom: (e) => fn.setField(e, 'itemName'),
    },
  ];
};

export const ReportCountStockSearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'startDateCheckStock',
      label: 'เริ่มวันที่',
      placeholder: 'เลือกวันที่เริ่ม',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 6,
      name: 'endDateCheckStock',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 6,
      name: 'documentNo',
      label: 'เลขที่ใบนับสต็อก',
      placeholder: 'กรอกชื่อเลขที่ใบนับสต็อก',
      type: 'AUTO_COMPLETE',
      options: option.optionDocNo,
    },
    {
      key: '4',
      span: 6,
      name: 'userCreate',
      label: 'ผู้สร้าง',
      placeholder: 'กรอกชื่อผู้สร้าง',
      type: 'AUTO_COMPLETE',
      options: option.optionUserCreate,
    },
  ];
};

export const ContractListSearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 3,
      name: 'tel',
      label: 'เบอร์โทร',
      placeholder: 'กรุณากรอกเบอร์โทร',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 3,
      name: 'contractNumber',
      label: 'เลขที่สัญญา',
      placeholder: 'กรุณากรอกเลขที่สัญญา',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 3,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'เลือกสาขา',
      type: 'SELECT',
      options: option.optionBranch,
    },
    {
      key: '5',
      span: 3,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: option.optionStatus,
    },
    {
      key: '6',
      span: 3,
      name: 'startDate',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
      value: '',
    },
    {
      key: '7',
      span: 3,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
      value: '',
    },
  ];
};

export const CustomerListSearchForm = ({option, onClearCustomField, defaultSearch}) => {
  return [
    {
      key: '1',
      span: 5,
      name: 'id_card',
      label: 'บัตรปชช.',
      placeholder: 'กรุณากรอกเลขบัตรปชช',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 5,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 5,
      name: 'tel',
      label: 'เบอร์โทร',
      placeholder: 'กรุณากรอกเบอร์โทร',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 3,
      name: 'type',
      label: 'ประเภทลูกค้า',
      placeholder: 'กรุณาเลือกประเภทลูกค้า',
      options: option.optionCustomerType,
      type: 'SELECT',
      value: defaultSearch.type !== null ? defaultSearch.type : null,
      onClear: () => onClearCustomField(),
    },
  ];
};

export const TrackPaymentListSearchForm = ({option, onClearCustomField, defaultSearch}) => {
  return [
    {
      key: '1',
      span: 4,
      name: 'id_card',
      label: 'บัตรปชช.',
      placeholder: 'กรุณากรอกเลขบัตรปชช',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 4,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 4,
      name: 'tel',
      label: 'เบอร์โทร',
      placeholder: 'กรุณากรอกเบอร์โทร',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 4,
      name: 'stage',
      label: 'เกินกำหนด',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option.optionStage,
      value: '',
    },
    {
      key: '5',
      span: 4,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือก',
      options: option.optionStatus,
      type: 'SELECT',
      value: defaultSearch.status !== null ? defaultSearch.status : null,
      onClear: () => onClearCustomField(),
    },
    {
      key: '6',
      span: 4,
      name: 'has_token_only',
      label: 'แจ้งเตือน app',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option.optionChannelApp,
      value: '',
    },
  ];
};

export const ReportTrackingFeeSearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 3,
      name: 'startDate',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 3,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 5,
      name: 'IDtrackers',
      label: 'ชื่อผู้ติดตาม',
      placeholder: 'กรอกชื่อผู้ติดตาม',
      type: 'SELECT',
      options: option?.optionTrackers,
      value: '',
    },
    {
      key: '4',
      span: 5,
      name: 'name',
      label: 'ชื่อ-สกุล (ลูกค้า)',
      placeholder: 'กรอกชื่อ-สกุล (ลูกค้า)',
      type: 'INPUT',
      value: '',
    },
    {
      key: '5',
      span: 4,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option.optionStatus,
      value: '',
    },
  ];
};

export const ReportTrackingDailySearchForm = ({option}) => {
  return [
    {
      key: '1',
      span: 8,
      name: 'startDate',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 8,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 8,
      name: 'IDtrackers',
      label: 'ชื่อผู้ติดตาม',
      placeholder: 'กรอกชื่อผู้ติดตาม',
      type: 'SELECT',
      options: option?.optionTrackers,
      value: '',
    },
  ];
};

export const ReportTrackingAgentSearchForm = ({option, dataSearch, setField}) => {
  return [
    {
      key: '1',
      span: 3,
      name: 'startDate',
      label: 'เริ่มวันที่บันทึก',
      placeholder: 'เลือกวันที่เริ่มบันทึก',
      type: 'DATE_PICKER',
      onChange: (e) => setField(e, 'startDate'),
      value: dataSearch.startDate !== '' ? moment(dataSearch.startDate, 'YYYY-MM-DD') : '',
    },
    {
      key: '2',
      span: 3,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
      onChange: (e) => setField(e, 'endDate'),
      value: dataSearch.endDate !== '' ? moment(dataSearch.endDate, 'YYYY-MM-DD') : '',
    },
    {
      key: '2',
      span: 5,
      name: 'IDtrackers',
      label: 'ชื่อผู้ติดตาม',
      placeholder: 'กรอกชื่อผู้ติดตาม',
      type: 'SELECT',
      options: option?.optionTrackers,
      onChangeCustom: (e) => setField(e, 'IDtrackers'),
      value: dataSearch.IDtrackers !== null ? dataSearch.IDtrackers : null,
      // value: '',
    },
    {
      key: '3',
      span: 5,
      name: 'name',
      label: 'ชื่อ-สกุล (ลูกค้า)',
      placeholder: 'กรอกชื่อ-สกุล (ลูกค้า)',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 4,
      name: 'contractNo',
      label: 'เลขที่สัญญา',
      placeholder: 'เลขที่สัญญา',
      type: 'INPUT',
      value: '',
    },
    {
      key: '5',
      span: 3,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: option?.optionTrackingRecordStatus,
      value: '',
    },
  ];
};

export const DownPaymentSearchForm = (dataSearch) => {
  return [
    {
      key: '1',
      span: 4,
      name: 'user_id_card',
      allowClear: true,
      defaultValue: dataSearch.user_id_card,
      label: 'บัตรปชช.',
      placeholder: 'กรุณากรอกเลขบัตรปชช',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 4,
      name: 'name',
      allowClear: true,
      defaultValue: dataSearch.name,
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 4,
      name: 'tel',
      allowClear: true,
      defaultValue: dataSearch.tel,
      label: 'เบอร์โทร',
      placeholder: 'กรุณากรอกเบอร์โทร',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 3,
      name: 'start_date',
      label: 'วันที่ชำระ',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '5',
      span: 3,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
  ];
};

export const DownPaymentReportDailySearchForm = (dataSearch) => {
  return [
    {
      key: '1',
      span: 3,
      name: 'start_date',
      label: 'วันที่ชำระ',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '1',
      span: 3,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
  ];
};

export const DownPaymentReportSearchForm = () => {
  return [
    {
      key: '1',
      span: 6,
      name: 'down_payment_id_book',
      label: 'เลขที่หนังสือออมดาวน์',
      placeholder: 'กรุณากรอกเลขที่หนังสือออมดาวน์',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'user_id_card',
      label: 'บัตรประชาชน',
      placeholder: 'กรุณากรอกเลขบัตรประชาชน',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทร',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'status',
      label: 'สถานะ',
      type: 'SELECT',
      placeholder: 'เลือกสถานะ',
      allowClear: true,
      options: [
        {value: 'WAIT_PAYMENT', label: 'รอชำระเงิน'},
        {value: 'ACTIVE', label: 'กำลังใช้งาน'},
        {value: 'WAIT_PROMISE', label: 'รอทำสัญญา'},
        {value: 'NEW_PROMISE', label: 'ทำสัญญาเสร็จสิ้น'},
        {value: 'WAIT_FINANCE_APPROVE', label: 'รอการเงินอนุมัติ'},
        {value: 'CANCEL', label: 'ยกเลิก'},
      ],
      value: '',
    },
    {
      key: '6',
      span: 6,
      name: 'start_date',
      label: 'เริ่มวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      value: '',
    },
    {
      key: '7',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      value: '',
    },
  ];
};

export const DownPaymentFinanceSearchForm = () => {
  return [
    {
      key: '1',
      span: 8,
      name: 'down_payment_id_book',
      label: 'เลขที่หนังสือออมดาวน์',
      placeholder: 'เลขที่หนังสือออมดาวน์',
      type: 'INPUT',
      value: '',
    },
  ];
};

export const IcloudTrackingStatusSearchForm = ({option}) => {
  return [
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'tel',
      label: 'เบอร์ลูกค้า',
      placeholder: 'กรุณากรอกเบอร์',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      placeholder: 'กรุณากรอกเลขที่',
      type: 'INPUT',
      value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'email',
      label: 'อีเมล',
      placeholder: 'กรุณากรอกอีเมล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '7',
      span: 6,
      name: 'job_type',
      label: 'Job type',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      allowClear: true,
      options: option.jobTypeOption,
      value: '',
    },
    {
      key: '6',
      span: 6,
      name: 'icloud_status',
      label: 'สถานะ icloud',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      allowClear: true,
      options: option.icloudStatusOption,
      value: '',
    },
    {
      key: '9',
      span: 6,
      name: 'start_date',
      label: 'เริ่มค้นหาจากวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '10',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
  ];
};

export const IcloudTrackingStatusLogSearchForm = ({option}) => {
  return [
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'tel',
      label: 'เบอร์ลูกค้า',
      placeholder: 'กรุณากรอกเบอร์',
      type: 'INPUT',
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      placeholder: 'กรุณากรอกเลขที่',
      type: 'INPUT',
      value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'email',
      label: 'อีเมล',
      placeholder: 'กรุณากรอกอีเมล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '7',
      span: 6,
      name: 'job_type',
      label: 'Job type',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      allowClear: true,
      options: option.jobTypeOption,
      value: '',
    },
    {
      key: '6',
      span: 6,
      name: 'icloud_status',
      label: 'สถานะ icloud',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      allowClear: true,
      options: option.icloudStatusOption,
      value: '',
    },
    {
      key: '9',
      span: 6,
      name: 'start_date',
      label: 'เริ่มค้นหาจากวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '10',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
  ];
};

export const ManageSpecialProductSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 3,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'กรุณาเลือกสาขา',
      type: 'SELECT',
      options: options.optionBranch,
      allowClear: true,
      value: '',
    },
    {
      key: '2',
      span: 4,
      name: 'documentNo',
      label: 'เลขที่ใบสั่งซื้อ',
      placeholder: 'กรุณากรอกเลขที่ใบสั่งซื้อ',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '3',
      span: 4,
      name: 'product',
      label: 'ชื่อสินค้า',
      placeholder: 'กรุณากรอกชื่อสินค้า',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '4',
      span: 3,
      name: 'serial_number',
      label: 'Serial Number',
      placeholder: 'กรุณากรอก S/N',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '5',
      span: 3,
      name: 'serial_status',
      label: 'สถานะสินค้า',
      placeholder: 'เลือกสถานะสินค้า',
      type: 'SELECT',
      options: [
        {value: '', label: 'ทั้งหมด'},
        {value: 'READY', label: 'พร้อมขาย'},
        {value: 'NOT_READY', label: 'ไม่พร้อมขาย'},
      ],
      allowClear: true,
      // defaultValue: defaultSearch.status,
      value: '',
    },
  ];
};

export const EditSecondHandPriceSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 12,
      name: 'product',
      label: 'สินค้า',
      placeholder: 'กรุณากรอกชื่อสินค้า',
      type: 'SELECT',
      allowClear: true,
      options: options.optionProduct,
      value: '',
    },
  ];
};

export const ReportStockBranchSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 8,
      name: 'name',
      label: 'ชื่อสินค้า',
      placeholder: 'กรุณาเลือกชื่อสินค้า',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'type',
      label: 'ประเภทสินค้า',
      placeholder: 'กรุณาเลือกประเภทสินค้า',
      type: 'SELECT',
      options: options.optionItemType,
      allowClear: true,
      value: '',
    },
  ];
};

export const MovementReportSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 4,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'เลือกสาขา',
      type: 'SELECT',
      options: options.optionBranch,
    },
    {
      key: '2',
      span: 6,
      name: 'name',
      label: 'ชื่อสินค้า',
      placeholder: 'กรอกชื่อสินค้า',
      type: 'INPUT',
    },
    {
      key: '3',
      span: 6,
      name: 'serial_number',
      label: 'Serial Number',
      placeholder: 'กรอกซีเรียล',
      type: 'INPUT',
    },
    {
      key: '4',
      span: 3,
      name: 'type',
      label: 'ประเภท',
      placeholder: 'เลือกประเภท',
      type: 'SELECT',
      options: options.optionType,
    },
    {
      key: '5',
      span: 6,
      name: 'start_date',
      label: 'วันที่เริ่ม',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
    {
      key: '6',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่สิ้นสุด',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
    },
  ];
};
